import React, {useContext} from 'react'
import {TreatmentContext} from 'providers/treatmentProvider';
import styles from './LateralCard.module.scss'
import Button from 'components/UI/Button/Button'



const LateralCard = (props) => {
    const { className, color } = props
    const [store, setStore] = useContext(TreatmentContext);
    const noTreatment = !store.allClick && 
        !store.volbellaClick && 
        !store.voliftClick && 
        !store.volumaClick && 
        !store.voluxClick && 
        !store.voliteClick
    
    return (
        <article className={`${styles.InfoCard} ${color === 'light' ? styles.LightViolet : styles.DarkViolet}${className ? ` ${className}` : ''}`}>
            { !noTreatment &&
                <>
                <h1>Tercio Superior</h1>
                {(store.allClick || store.volumaClick) && <h4>Sienes: <span className={styles.Green}>VOLUMA®<sup>3</sup></span></h4>}
                {(store.allClick || store.voliftClick) && <h4>Cejas: <span className={styles.Violet}>VOLIFT®<sup>4</sup></span></h4>}
                {(store.allClick || store.volbellaClick) && <h4>Líneas Periorbiatales: <span className={styles.Pink}>VOLBELLA®<sup>1</sup></span></h4>}
                {(store.allClick || (store.volbellaClick && !store.voliftClick)) && <h4>Líneas de la frente: <span className={styles.Pink}>VOLBELLA®<sup>1</sup></span></h4>}
                {(store.allClick || (!store.volbellaClick && store.voliftClick)) && <h4>Líneas de la frente: <span className={styles.Violet}>VOLIFT®<sup>4</sup></span></h4>}
                {(store.allClick || (store.volbellaClick && store.voliftClick)) && <h4>Líneas de la frente: <span className={styles.Violet}>VOLIFT®<sup>4</sup></span> y <span className={styles.Pink}>VOLBELLA®<sup>1</sup></span></h4>}
                <div className={styles.DividerInfoCard} />
                <h1>Tercio Medio</h1>
                {(store.allClick || store.volbellaClick) && <h4>Ojera: <span className={styles.Pink}>VOLBELLA®<sup>1</sup></span></h4>}
                {(store.allClick || store.volumaClick) && <h4>Pómulos: <span className={styles.Green}>VOLUMA®<sup>3</sup></span></h4>}
                {(store.allClick || store.voliftClick) && <h4>Surco nasogeniano: <span className={styles.Violet}>VOLIFT®<sup>4</sup></span></h4>}
                {(store.allClick || store.voliftClick) && <h4>Mejilla: <span className={styles.Violet}>VOLIFT®<sup>4</sup></span></h4>}

                <div className={styles.DividerInfoCard} />
                <h1>Tercio Inferior</h1>
                {(store.allClick || store.volbellaClick) && <h4>Líneas Periorales: <span className={styles.Pink}>VOLBELLA®<sup>1</sup></span></h4>}
                {(store.allClick || store.volbellaClick) && <h4>Hidratación de labios: <span className={styles.Pink}>VOLBELLA®<sup>1</sup></span></h4>}
                {(store.allClick || store.voliftClick) && <h4>Labio: <span className={styles.Violet}>VOLIFT®<sup>4</sup></span></h4>}
                {(store.allClick || store.voluxClick) && <h4>Mentón: <span className={styles.DarkBlue}>VOLUX<sup>TM</sup><sup>2</sup></span></h4>}
                {(store.allClick || store.voliftClick) && <h4>Líneas de marioneta: <span className={styles.Violet}>VOLIFT®<sup>4</sup></span></h4>}
                {(store.allClick || store.voluxClick) && <h4>Línea mandibular: <span className={styles.DarkBlue}>VOLUX<sup>TM</sup><sup>2</sup></span></h4>}


                {(store.allClick || store.voliteClick) &&
                    <>
                        <div className={styles.DividerInfoCard} />
                        <h1>Skin Quality</h1>
                        <h4>Para mejorar las depresiones y aumentar la hidratación y elasticidad de la piel, utilice <span className={styles.Blue}>VOLITE<sup>TM</sup></span><sup>5</sup> &ensp;como tratamiento de elección</h4>
                    </>
                }
                </>
            }
            {noTreatment &&
                <h4>Elija tratamiento</h4>
            }
            {!noTreatment &&
                <Button className={styles.FloatingButton} onClick={() => setStore({...store, modalOpen: true})}>
                    <i>Ver Tabla Comparativa</i>
                </Button>
            }
     
        </article>
    )
}

export default LateralCard