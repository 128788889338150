import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons'

import logoAllerganBlack from 'assets/images/logo-allergan-black.svg'
import logoAllerganWhite from 'assets/images/logo-allergan-white.svg'
import logoAllerganMobile from 'assets/images/juvederm-mobile-logo.svg'
import menuIconAlleganMobile from 'assets/images/juvederm-mobile-icon.svg'

import styles from './Header.module.scss'

const Header = (props) => {
    const { className, logo, color, inverted, title, menuIcon, onMenuIconClick } = props

    const colorMap = {
        'white': styles.WhiteBg,
        'pink': styles.PinkBg,
        'grape': styles.GrapeBg,
    }

    return (
        <header className={`${styles.Header} ${colorMap[color] || styles.PinkBg}${className ? ` ${className}` : ''}`}>
            {
                // menuIcon &&
                // <FontAwesomeIcon icon={faBars} onClick={onMenuIconClick} size='2x' className={styles.MenuIcon}/>

                 menuIcon &&
                 <img src={menuIconAlleganMobile} alt='icon' onClick={onMenuIconClick} className={styles.MenuIcon}/>
            }
            {
                logo
                    ? <img className={styles.Logo} src={logo} alt='Logo' />
                    : <div />
            }
            <div className={styles.LogoMobile}>
                <img
                    
                    src={logoAllerganMobile}
                    alt="Logo Allergan"
                />
                {
                    title
                        ? <p className={styles.Title}>{title}</p>
                        : <p />
                }
            </div>
            <div className={styles.LogoDesktop}>
                {
                    title
                        ? <p className={styles.Title}>{title}</p>
                        : <p />
                }
            </div>
            <div  className={styles.LogoDesktop}>
                <img
                    src={inverted ? logoAllerganWhite : logoAllerganBlack}
                    alt="Logo Allergan"
                />
            </div>
        </header>
    )
}

export default Header