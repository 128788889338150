import React, {useContext, useEffect, useState} from 'react'
import { faCirclePlus } from '@fortawesome/free-solid-svg-icons'
import { Helmet } from 'react-helmet-async'
import { useNavigate } from 'react-router-dom'
import { paths } from 'assets/constants/navigation'
import harmonycaLogo from 'assets/images/logo-harmonyca.png'
import harmonycaLogo2 from 'assets/images/logo-harmonyca-white.png'
import juvedermLogo from 'assets/images/juvederm-shadow.png'
import shield from 'assets/images/shield.png'
import file from 'assets/images/file.svg'
import points from 'assets/images/points.png'
import vistabelLogo from 'assets/images/vistabel-shadow.png'
import Button from 'components/UI/Button/Button'
import ChangeBtn from 'components/UI/ChangeBtn/ChangeBtn'
import Header from 'components/UI/Header/HeaderHarmonyca'
import MainContent from 'components/UI/MainContent/MainContent'
import Head from 'components/UI/Head/Head'
import Footer from 'components/Footer/FooterHarmonyca'
import styles from './Harmonyca.module.scss'
import { TreatmentContext } from 'providers/treatmentProvider'
import Modal from 'components/UI/Modal/Modal'
//import referenceIcon from 'assets/images/ref-harmonyca-info.svg'
import referenceIcon from 'assets/images/reference-icon.svg'



const Harmonyca = () => {
    const [isModalVisible, setModalVisible] = useState(false)
    const [isTableModalVisible, setTableModalVisible] = useState(false)
    const [store,setStore] = useContext(TreatmentContext)
    const navigate = useNavigate()
    
    useEffect(() => {
        setStore({...store, vistabel: false, harmonyca: true})
    },[])

    return (
        <>
            <Helmet>
                <title>Harmonyca</title>
            </Helmet>
            <Header
                className={styles.Header}
                logo={harmonycaLogo}
                color='white'
                title='HArmonyCa<sub>TM</sub> ha sido diseñado para uso subdérmico y dérmico profundo.'
            />
            <MainContent className={styles.MainContent}>
                <div className={styles.FirstColumn}>
                    <div className={styles.FirstSection}>
                        <img className={styles.shield} src={file} alt='shield' />
                        <h1 className={styles.shieldHeader}>Perfil de seguridad<sup>1</sup></h1>
                        {/* <img className={styles.points} src={points} alt='shield' /> */}
                        <p className={styles.text} >En una investigación realizada en <strong>104 paciente tras 18 meses del tratamiento con HArmonyCa<sup>TM</sup>,</strong>
                            ningún paciente desarrolló efectos adverso a largo plazo.</p> 
                            <br></br>
                        <p  className={styles.text} ><strong>NO se detectaron anomalías cutáneas, </strong>
                            reacciones alérgicas, granulómas o infecciones ni en examen visual ni por dermatoscopia.</p>
                    </div>
                    <div className={styles.BtnSection}>
                        <Button
                            className={styles.CenteredButton}
                            color='lollipop'
                            icon={faCirclePlus}
                            onClick={() => navigate(paths.HARMONYCAINFO)}
                        >
                            <span>Más Información</span>
                        </Button>
                    </div>
                    
                    <div className={styles.InfoIcon} >
                        <img
                            src={referenceIcon} alt='Botón de información'
                            onClick={() => setModalVisible(true)}
                        />  
                    </div>
                </div>
                <div className={styles.SecondColumn}>
                    <Head/>
                </div>
                <div className={styles.ThirdColumn}>

                    <div className={styles.ChangeTo}>
                                <p className={styles.ChangeToText}>Cambia a</p>
                                <ChangeBtn
                                    className={"harmonyca"}
                                    logo={vistabelLogo}
                                    onClick={() => {
                                        setStore({...store, vistabel: true})
                                        navigate(paths.VISTABEL)
                                    }}    
                                />
                            </div>

                    <div className={styles.ChangeTo}>
                        <p className={styles.ChangeToText}>Cambia a</p>
                        <ChangeBtn
                        className={"harmonyca"}
                            logo={juvedermLogo}
                            onClick={() => {
                                setStore({...store, vistabel: false})
                                navigate(paths.HOME)
                            }}    
                        />
                    </div>
                    
                </div>
            </MainContent>
            <Footer color={'orange'} logo={harmonycaLogo2}/>
            {
                isModalVisible &&
                <Modal onClose={() => setModalVisible(false)} className={styles.ReferencesModal}>
                    <div>
                        <p>
                            Se recomienda consultar la Ficha Técnica/Instrucciones de uso de cada producto 
                            antes de iniciar un tratamiento.
                        </p>
                        <p>
                        1. F.Urdiales-Gálvez, L.Elmaleh, L. Goldshaid-Zmiri, M.Salomon. Long-term Safety With a Hyaluronic Acid and Calcium Hydroxyapatite Combination Filler in Facial Aesthetics Rejuvenation. Presented at the International Master Course on Aging (IMCAS); June 3-5, 2022; Paris, France
                        </p>
                    </div>
                </Modal>
            }
            {
                isTableModalVisible &&
                <Modal onClose={() => setTableModalVisible(false)} className={styles.ReferencesModal}>
                    <div>
                        <p>
                        1. Ficha Técnica de Vistabel 4 Unidades Allergan / 0,1ml polvo para solución inyectable (toxina botulínica tipo A) disponible. 2. Kerscher M, Roll S, Becker A, Wigger-Alberti W. Comparison of the spread of three botulinum toxin type A preparations. Arch Dermatol Res. 2012 Mar;304(2):155-61. 3. De Boulle K, Werschler WP, Gold MH, Bruce S, Sattler G, Ogilvie P, Street J, Larsen KE, Yushmanova I, Lei X, Lee E, Vitarella D, Mao C. Phase 3 Study of OnabotulinumtoxinA Distributed Between Frontalis, Glabellar Complex, and Lateral Canthal Areas for Treatment of Upper Facial Lines. Dermatol Surg. 2018 Nov;44(11):1437-1448. 4.  Rivkin AZ, Ogilvie P, Dayan S, Yoelin SG, Weichman BM, Garcia JK. OnabotulinumtoxinA for Simultaneous Treatment of Upper Facial Lines: Subject-Reported Satisfaction and Impact From a Phase 3 Study. Dermatol Surg. 2020 Jan;46(1):50-60. 5. Brin MF, Boodhoo TI, Pogoda JM, James LM, Demos G, Terashima Y, Gu J, Eadie N, Bowen BL. Safety and tolerability of onabotulinumtoxinA in the treatment of facial lines: a meta-analysis of individual patient data from global clinical registration studies in 1678 participants. J Am Acad Dermatol. 2009 Dec;61(6):961-70.e1-11.


                        </p>
                    </div>
                </Modal>
            }
        </>
    )
}

export default Harmonyca