import React, {createContext, useState} from 'react';

const default_treatment = {
    volbellaClick:  false,
    voliftClick:    false,
    volumaClick:    false, 
    voluxClick:     false,
    voliteClick:    false,
    allClick:       false,
    mdCodes:        true,
    areas:          true,
    face:           false,
    modalOpen:      false,
    vistabel:       false,
    harmonyca:      false,
    loading:        true
}

const TreatmentProvider = ({children}) => {
    const [state, setState] = useState(default_treatment);
    return(
        <TreatmentContext.Provider value={[state, setState]}>
            {children}
        </TreatmentContext.Provider>
    );
}

export default TreatmentProvider;
export const TreatmentContext = createContext();