import React, { useState } from 'react'

import { Helmet } from 'react-helmet-async'
import { useNavigate } from 'react-router-dom'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'

import { paths } from 'assets/constants/navigation'
import referenceIcon from 'assets/images/reference-icon.svg'
import vistabelLogo from 'assets/images/logo-vistabel.png'
import vistabelPills from 'assets/images/vistabel-pills.png'
import grafico from 'assets/images/grafica.PNG'
import vistabelLogo2 from 'assets/images/logo-vistabel-white.svg'
import IconLink from 'components/UI/IconLink/IconLink'
import Header from 'components/UI/Header/Header'
import styles from './VistabelInfo.module.scss'
import Footer from 'components/Footer/Footervistabel'
import Modal from 'components/UI/Modal/Modal'


const VistabelInfo = () => {
    const [isModalVisible, setModalVisible] = useState(false)
    const navigate = useNavigate()

    return (
        <>
            <Helmet>
                    <title>Vistabel - Info</title>
            </Helmet>
            <Header
                className={styles.Header}
                logo={vistabelLogo}
                color='white'
            />
            <main className={styles.Body}>
                <div className={styles.DetailsWrapper}>
                    <h1 className={styles.Title}>Las 3P&apos;s de Vistabel<sup>®</sup></h1>
                    <div className={styles.MainGrid}>
                        <div className={styles.ChildGrid}>
                            <h2>Probada<sup>1,2,3</sup></h2>
                            <div className={styles.SubTitle}>
                                Nuestra experiencia e historia nos avalan.
                                </div>
                                <div className={styles.Text}>
                               La toxina botulínica de Allergan ha sido citada en mas de 2.800 artículos clínicos (sobre duración, perfil de seguridad, eficacia, inicio de acción) y no clínicos en indicaciones estéticas y terapeuticas.<sup>1</sup>
                                    </div>
                                    <div className={styles.Text}>
                               19 años de experiencia tras la primera indicación estética.<sup>2</sup>
                                    </div>
                                    <div className={styles.Text}>
                               Con un perfil de seguridad bien tolerado durante una media de 9,1 años.<sup>3</sup>
                                    </div>
                            </div>
                            <div className={styles.ChildGrid}>
                            <h2>Precisa<sup>4</sup></h2>
                            <div className={styles.SubTitle}>
                                La difusión limitada de Vistabel ® hace que sea una de las toxinas más predecibles.<sup>4</sup>
                                </div>
                                <div className={styles.Text}>
                               En estética facial es deseable una toxina botulínica con potencial de difusión limitado y predecible.<sup>4</sup>
                                    </div>
                                    <div className={styles.Text}>
                               Es importante que la toxina no se se difunda en los músculos cercanos que no son objeto de inyección, para minimizar los efectos no deseados.
                                    </div>
                            </div>
                            <div className={styles.ChildGrid}>
                            <h2>Preferida por los pacientes<sup>5,6</sup></h2>
                            <div className={styles.SubTitle}>
                                Vistabel ® mostró resultados diferenciadores frente a los competidores por alta satisfacción y bienestar de los pacientes.<sup>5,6,7</sup>
                                </div>
                                <div className={styles.Text}>
                               Los pacientes informan de satisfacción con el tratamiento en &gt;90% en las visitas onabotulinumtoxinA en comparación con el 34% de las visitas con incobotulinumtoxinA (lineas glabelares) (n=110).<sup>6</sup><br/>
                               El 100% de los pacientes (n=40) con onabotulinumtoxinA estaban satisfechos o extremadamente satisfechos frente al 31% con abobotulinumtoxinA (líneas de la frente).<sup>7</sup>
                                    </div>
                                    <div className={styles.Text}>
                               La duración de VISTABEL® muestra una mejora en el impacto emocional y una satisfacción hasta 6 meses después (n=876).<sup>8</sup>
                                    </div>
                            </div>
                        </div>
                    {/* <div className={styles.Details}>
                        <div className={styles.FirstColumn}>
                        <div>
                                <h3>• PROBADO</h3>
                                <p className={styles.subtitle}>NUESTRA EXPERIENCIA E HISTORIA NOS AVALAN. Estudios clínicos<sup>1</sup>:</p>
                                <p>La toxina botulínica de Allergan ha sido citada en mas de <span className={styles.highlighted}> 2.800</span> artículos clínicos y no clínicos en indicaciones estéticas y terapeuticas.<sup>1</sup><br></br>
                                <span className={styles.highlighted}>18 años</span> de experiencia tras la primera indicación estetica.<sup>2</sup>

                                </p>
                            </div>
                            <img className={styles.VistabelPills}src={vistabelPills} alt="Píldoras" />
                        </div>
                        <div className={styles.SecondColumn}>
                        <div>
                                <h3>• PRECISO</h3>
                                <p className={styles.subtitle}>EN ESTÉTICA FACIAL ES DESEABLE UNA TOXINA BOTULÍNICA CON POTENCIAL DE DIFUSIÓN LIMITADO Y PREDECIBLE.<sup>3</sup></p>

                                <p>La difusión limitada de VISTABEL® hace que sea una de las toxinas más predecibles. Es importante que la toxina no se difunda en los músculos cercanos que no son objeto de inyección, para minimizar los efectos no deseados. Efectos no deseados pueden ocurrir si la neurotoxina se difunde en músculos no dirigidos.</p>
                                <img className={styles.grafico}src={grafico} alt="grafico"  align = "center" />
                            <p>Gráfica obtenida del estudio de Kerscher M, Roll S, Becker A, Wigger-Alberti W – 2012.</p>
                            </div>
                          
                            
                            <div>
                           
                               
                            </div>
                        </div>
                        <div className={styles.ThirdColumn}>
                                    <h3>• PREFERIDO POR LOS PACIENTES</h3>
                                    <p className={styles.subtitle}>VISTABEL® mostró resultados diferenciadores frente a los competidores*<sup>4,5</sup>
</p>
                                <p>Los pacientes informan de satisfacción con el tratamiento en  <span className={styles.highlighted}> &gt;90% </span> de las visitas con onabotulinumtoxinA en comparacion con el <span className={styles.highlighted}>34%</span> de las visitas con incobotulinumtoxinA (lineas glabelares)<sup>4</sup>.<br></br>
                                El <span className={styles.highlighted}>100% </span>de los pacientes con onabotulinumtoxinA estaban satisfechos o extremadamente satisfechos frente al 31% con abobotulinumtoxinA (líneas de la frente)<sup>5</sup>.

                                </p>
                                </div>
                    </div> */}
                </div>
                <div className={styles.BackBtn}>
                    <IconLink
                        title='Volver'
                        icon={faArrowLeft}
                        className={styles.BackLink}
                        onClick={() => navigate(paths.VISTABEL)}
                    />
                </div>
            </main>
            <img
                src={referenceIcon}
                alt='Reference icon'
                className={styles.ReferenceIcon} 
                onClick={() => setModalVisible(true)}
            />
            <Footer color={'grey'} logo={vistabelLogo2}/>
            {
                isModalVisible &&
                <Modal onClose={() => setModalVisible(false)} className={styles.ReferencesModal}>
                    <div className={styles.References}>
                        <div className={styles.Reference}>
                        1. Brin MF, James C, Maltman J. Botulinum toxin type A products are not interchangeable: a review of the evidence. Biologics. 2014 Oct 6;8:227-41.
                        </div>
                        <div className={styles.Reference}>
                        2. Ficha Tecnica de VISTABELR 4 Unidades Allergan /0,1ml polvo para solucion inyectable (toxina botulinica tipo A) adjunta.
                        </div>
                        <div className={styles.Reference}>
                        3. Trindade de Almeida A, Carruthers J, Cox SE, Goldman MP, Wheeler S, Gallagher CJ. Patient satisfaction and safety with aesthetic onabotulinumtoxinA after at least 5 years: a retrospective cross-sectional analysis of 4,402 glabellar treatments. Dermatol Surg. 2015 Jan;41 Suppl 1:S19-28.
                        </div>
                        <div className={styles.Reference}>
                        4. Kerscher M, Roll S, Becker A, Wigger-Alberti W. Comparison of the spread of three botulinum toxin type A preparations. Arch Dermatol Res. 2012 Mar;304(2):155-61. *Comparando onabotulinumtoxinA frente a incobotulinumA.
                        </div>
                        <div className={styles.Reference}>
                        5. Cohen JL et al. High Patient Satisfaction for up to 6 Months With OnabotulinumtoxinA Treatment for Upper Facial Lines. Dermatol Surg. 2022 Nov.
                        </div>
                        <div className={styles.Reference}>
                        6. Banegas RA, Farache F, Rancati A, Chain M, et al. The South American Glabellar Experience Study (SAGE): a multicenter retrospective analysis of real-world treatment patterns following the introduction of incobotulinumtoxinA in Argentina. Aesthet Surg J. 2013 Sep 1;33(7):1039-45.
                        </div>
                        <div className={styles.Reference}>
                        7. de Boulle K. Patient satisfaction with different botulinum toxin type A formulations in the treatment of moderate to severe upper facial rhytids. J Cosmet Laser Ther. 2008 Jun;10(2):87-92. 
                        </div>
                        <div className={styles.Reference}>
                        8. Rivkin AZ, et al. Onaboutlinumtoxin A for simultaneous treatment of upper facial lines: Subject-Reported Satisfaction and Impact From a Phase 3 Study. Dermatol Surg. 2020 Jan;46(1):50-60.
                        </div>
                        </div>
                </Modal>
            }
        </>
    )
}

export default VistabelInfo