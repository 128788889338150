import React, { useState } from 'react'

import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'
import { useNavigate } from 'react-router-dom'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'

import { paths } from 'assets/constants/navigation'
import privacyDoc from 'assets/docs/AvisoPrivacidad.pdf'
import userManual from 'assets/docs/GuiaUsuario.pdf'
import headerLiana from 'assets/images/Liana-Hylacross-Header.png'
import downloadIcon from 'assets/images/download-icon.svg'
import juvedermLogo from 'assets/images/logo-juvederm-2.svg'
import hylacrossLogo from 'assets/images/logo-juvederm-hylacross-header.svg'
import calendarIcon from  'assets/images/calendar-icon.svg'
import carbonIcon from  'assets/images/carbon-icon.svg'
import injectionIcon from  'assets/images/injection-icon.svg'
import statsIcon from  'assets/images/stats-icon.svg'
import referenceIcon from 'assets/images/reference-icon.svg'
import Header from 'components/UI/Header/Header'
import HylacrossProductsTable from 'components/HylacrossProductsTable/HylacrossProductsTable'
import IconLink from 'components/UI/IconLink/IconLink'
import Modal from 'components/UI/Modal/Modal'
import styles from './JuvedermHylacrossInfo.module.scss'


const BenefitInfo = (props) => {
    const { icon, text } = props

    return (
        <div className={styles.BenefitInfo}>
            <div className={styles.BenefitIcon}>
                <img src={icon} alt='Benefit icon' />
            </div>
            <p className={styles.BenefitDescription}>
                {text}
            </p>
        </div>
    )
}


const JuvedermHylacrossInfo = () => {
    const [isModalVisible, setModalVisible] = useState(false)
    const navigate = useNavigate()

    return (
        <>
            <Helmet>
                <title>Juvéderm Hylacross - Info</title>
            </Helmet>
            <Header
                inverted
                logo={hylacrossLogo}
                color='grape'
                className={styles.Header}
            />
            <main className={styles.Content}>
                <section>
                    <img src={headerLiana} alt='Liana' />
                    <h1>
                        <span>Tecnología</span>
                        <span>consolidada</span>
                        <span>y contrastada</span>
                    </h1>
                </section>
                <section>
                    <h2>
                        Ventajas y beneficios
                    </h2>
                    <div className={styles.SmallSeparator} />
                    <div className={styles.Benefits}>
                        <div className={styles.BenefitsColumn}>
                            <BenefitInfo
                                icon={calendarIcon}
                                text={(
                                    <>
                                        Resultados de <strong>larga duración</strong> (hasta 12 meses). <sup>1,2</sup><br />
                                       
                                    </>
                                )}
                            />
                            <BenefitInfo
                                icon={carbonIcon}
                                text={(
                                    <>
                                        <strong className={styles.Capitalized}>Resultados uniformes</strong> y naturales <br />
                                        con la gama Juvéderm<sup>®</sup> ULTRA. <sup>1,2</sup>
                                    </>
                                )}
                            />
                        </div>
                        <div className={styles.BenefitsColumn}>
                            <BenefitInfo
                                icon={injectionIcon}
                                text={(
                                    <>
                                        Flujo de inyección uniforme y continuo. <sup>3,4</sup>
                                    </>
                                )}
                            />
                            <BenefitInfo
                                icon={statsIcon}
                                text={(
                                    <>
                                        Los productos de la gama Juvéderm<sup>®</sup> ULTRA <br />
                                        se encuentran entre los primeros geles de <br />
                                        AH del mundo en incorporar lidocaína para <br />
                                        mayor comodidad del paciente. <sup>5</sup>
                                    </>
                                )}
                            />
                        </div>
                    </div>
                    <img
                        src={referenceIcon}
                        alt='Reference icon'
                        className={styles.ReferenceIcon} 
                        onClick={() => setModalVisible(true)}
                    />
                </section>
                <section>
                    <HylacrossProductsTable className={styles.ProductsTable} />
                    <div className={styles.BackBtn}>
                        <IconLink
                            title='Volver'
                            icon={faArrowLeft}
                            className={styles.BackLink}
                            onClick={() => navigate(paths.HOME)}
                        />
                    </div>
                </section>
                <footer>
                    <div className={styles.FooterRow}>
                        <a href={userManual} target='_blank' rel='noreferrer'>
                            <span>Descargar Guía de Usuario</span>
                            <img src={downloadIcon} alt='Download icon' />
                        </a>
                        <div>
                            <p>El contenido de esta web está dirigido exclusivamente a profesional sanitario</p>
                        </div>
                        <div className={styles.PrivacyAndTerms}>
                            <Link
                                to={paths.PRIVACY_NOTICE}
                                target='_blank'
                                rel='noreferrer'
                                className={styles.Terms}
                            >
                                <p>Aviso de privacidad</p>
                            </Link>
                            <p>&nbsp;|&nbsp;</p>
                            <a
                                href='https://www.allerganaesthetics.es/terminos-y-condiciones'
                                target='_blank'
                                rel='noreferrer'
                                className={styles.Terms}
                            >
                                <p>Términos y condiciones</p>
                            </a>
                        </div>
                    </div>
                    <div className={styles.FooterRow}>
                        <img src={juvedermLogo} alt='Juvéderm icon' />
                        <div>
                        <p>ES-JUV-220030 &nbsp;/&nbsp; Fecha de elaboración: Enero 2023</p>
                        </div>
                        <div>
                        <p>©AbbVie S.L.U. 2022. Todos los derechos reservados</p>
                        </div>
                    </div>
                </footer>
            </main>
            {
                isModalVisible &&
                <Modal onClose={() => setModalVisible(false)} className={styles.ReferencesModal}>
                    <div>
                        <p>
                        1. Pinsky MA, Thomas JA, Murphy DK, Walker PS; Juvéderm vs. Zyplast Nasolabial Fold Study Group. Juvéderm injectable gel: a multicenter, double-blind, randomized study of safety and effectiveness. Aesthet Surg J. 2008 Jan-Feb;28(1):17-23. / 2. Wahl G. European evaluation of a new hyaluronic acid filler incorporating lidocaine. J Cosmet Dermatol. 2008 Dec;7(4):298-303. / 
3. Costello K, Dally N, Mallet C, Mandaroux B, A New Dermal Filler Syringe With Improved Ergonomic Performance to Enhance End-User Experience. IMCAS, June 3-5, 2022, Paris, France. 4. Costello K, Dally N, Mallet C, Mandaroux B, In-Field Assessment of the Perception of Smoothness When Dispensing Different Filler Formulations. IMCAS, June 3-5, 2022, Paris, France./ 5. Levy PM, De Boulle K, Raspaldo H. Comparison of injection comfort of a new category of cohesive hyaluronic acid filler with preincorporated lidocaine and a hyaluronic acid filler alone. Dermatol Surg. 2009 Feb;35 Suppl 1:332-6; discussion 337.
                        </p>
                    </div>
                </Modal>
            }
        </>
    )
}

export default JuvedermHylacrossInfo
